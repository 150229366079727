import React from 'react'
import styled from 'styled-components'
import CreateLink from '../components/linkbee/createLink'
import Linkbee from '../components/linkbee/linkbee'
import LinkbeeInfo from '../components/linkbee/linkbeeInfo'
import FullscreenSpinner from '../components/loader/fullscreen-spinner'
import { useUserData } from '../hooks/user'

const LinkManagerViewWrapper = styled.div`
  max-width: calc(100vw - 4rem);
  flex: 1;
  min-height: 100vh;
  @media (max-width: 800px) {
    max-width: 100vw;
  }
`

const LinkManagerView = () => {
  const userData = useUserData()
  const user = userData.user

  if (!user) {
    return (
      <LinkManagerViewWrapper>
        <FullscreenSpinner />
      </LinkManagerViewWrapper>
    )
  }

  // request access if we dont have
  if (!user?.linkbeeAccess) {
    return (
      <LinkManagerViewWrapper>
        <LinkbeeInfo />
      </LinkManagerViewWrapper>
    )
  }

  // create link flow if we don't have links
  if (!user?.links) {
    return (
      <LinkManagerViewWrapper>
        <CreateLink />
      </LinkManagerViewWrapper>
    )
  }

  // default view once we have links
  return (
    <LinkManagerViewWrapper>
      <Linkbee />
    </LinkManagerViewWrapper>
  )
}

export default LinkManagerView
