import React, { useEffect, useState } from 'react'
//import firebase from 'firebase'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'

import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth'

import { useNavigate, useParams } from 'react-router'
import { User } from '../../types/user'
import { writeUserData } from '../../firebase/utils'
import styled from 'styled-components'
import { BasicButton } from '../../style/buttons'
import { Link } from 'react-router-dom'
import { StyledLogoText } from '../../style/logo'
import FullscreenSpinner from '../loader/fullscreen-spinner'

const LoginWrapper = styled.div`
  background-color: white;
  width: 33%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    margin-top: 4rem;
    svg {
      height: 200px;
    }
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`
const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  h1 {
    margin: 0;
  }
  div {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0.8rem 0;
  }
  input {
    flex: 1;
    width: 100%;
    height: 2rem;
    padding: 0.6rem;
    color: #1d3557;
    background: #fff;
    border-radius: 6px;
    border: 2px solid #a8dadc;
    box-sizing: border-box;
    filter: none !important;
    transition: all 0.3s ease;
    &:focus {
      border: 2px solid #457b9d;
      outline: none;
    }
  }
`
const GoogleSignInWrapper = styled.div`
  margin-top: 2rem;
  padding: 0.2rem 0;
  width: 67%;
`
const CustomLink = styled(Link)`
  color: #3b3a3a;
  font-weight: bolder;
`

const ErrorWrapper = styled.p`
  color: #e63946;
`

const AppsumoSignup = () => {
  let params = useParams()
  const provider = new GoogleAuthProvider()
  const auth = getAuth()
  let navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [createUserWithEmailAndPassword, user, loading, error] = useCreateUserWithEmailAndPassword(auth)

  const googleSignup = async () => {
    try {
      const result = await signInWithPopup(auth, provider)
      const credentials = GoogleAuthProvider.credentialFromResult(result)
      const token = credentials?.accessToken

      const user = result.user

      // TODO create basic user depending on where they sign up from
      // basic data for first time users
      const userProfile: User = {
        uid: user.uid,
        email: user.email,
        paymentMode: 'free',
        lists: [
          {
            id: '1c5zDt-Kyy2rFFOXxSyv-1GWI38YWkaOB73VJiaUP8bY/instagram-influencer-sample',
            name: 'Instagram Influencer Sample',
            type: 'instagram',
          },
        ],
      }

      writeUserData(userProfile.uid, { ...userProfile })
    } catch (e) {
      console.error('google login error', e)
    } finally {
      navigate(`/database`)
    }
  }

  const emailSignup = async (emailVal: string, passwordVal: string) => {
    try {
      const response = await createUserWithEmailAndPassword(emailVal, passwordVal)
      /*if (user) {
        if (params.goal === 'sample') {
          navigate(`/lists/my-list`)
        } else {
          navigate(`/`)
        }
      }*/
    } catch (e) {
      console.error('email error', e)
    }
  }

  useEffect(() => {
    if (user) {
      // add the user if its email signup
      const createUserData = async () => {
        const userProfile: User = {
          uid: user.user.uid,
          email: user.user.email,
          paymentMode: 'database',
        }
        await writeUserData(userProfile.uid, { ...userProfile })
        navigate(`/database`)
      }
      createUserData()
    }
  }, [user])

  if (loading) {
    return <FullscreenSpinner />
  }
  return (
    <LoginWrapper>
      <div className='logo'>
        <StyledLogoText color='#1D3557' />
      </div>
      <LoginForm onSubmit={(e) => e.preventDefault()}>
        <div>
          <h1>Welcome to Omnifluence</h1>
        </div>
        <div>
          {error && <ErrorWrapper>{error.message}</ErrorWrapper>}
          <label>Email adress</label>
          <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div>
          <label>Password</label>
          <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div>
          <BasicButton primary onClick={() => emailSignup(email, password)}>
            Continue
          </BasicButton>
        </div>
      </LoginForm>
      <GoogleSignInWrapper>
        <BasicButton onClick={googleSignup}>Sign in with google</BasicButton>
      </GoogleSignInWrapper>
      <p>
        Already a member? <CustomLink to='/login'>Log in</CustomLink>
      </p>
    </LoginWrapper>
  )
}

export default AppsumoSignup
