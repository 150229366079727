import React from 'react'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'

const SpinnerWrapper = styled(Loader)`
  margin-top: 10rem;
`

const FullscreenSpinner = () => {
  return <SpinnerWrapper type='TailSpin' color='#457b9d' height={100} width={100} />
}

export default FullscreenSpinner
