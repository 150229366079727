import React, { FC, useEffect, useState } from 'react'
import { ExpanderComponentProps } from 'react-data-table-component'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useUserData } from '../../../hooks/user'
import { Influencer } from '../../../types/influencer'
import FullImage from './full-image'
import ProfileCard from './profileCard'
import ProfileInfo from './profileInfo'
import Statistics from './statistics'
import MockInfluencer from '../../../mock-data/influencer.json'
import InfluencerStoryContainer from './influencerStoryContainer'
import InfluencerCharts from './influencerCharts'

const StyledRow = styled.pre<{ paywall: boolean }>`
  cursor: ${({ paywall }) => (paywall ? 'not-allowed' : 'inherit')};
  ${({ paywall }) => {
    if (!paywall) {
      return
    }
    return css`
      color: transparent;
      text-shadow: 0 0 8px #000;
      background: rgba(0, 0, 0, 0.4);
    `
  }}
  width: 100%;
  margin: auto;
  background-color: #f1faee;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: auto;
  grid-template-areas:
    'profile-image stats charts'
    'name-info name-info charts'
    'hashtags hashtags charts'
    'stories stories  stories';
  justify-content: center;
  align-items: center;
  /* padding: 2rem;*/
  padding-bottom: 1rem;
  .name-info {
    text-align: left;
    grid-area: name-info;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #457b9d;
    margin-bottom: 1rem;
    column-gap: 1rem;
  }
  .profile-image {
    grid-area: profile-image;
    display: flex;
    justify-content: center;
    text-align: left;
    font-size: 0.7rem;
    font-family: sans-serif;
    font-weight: 800;
    img {
      opacity: ${({ paywall }) => (paywall ? '0.2' : '1')};
    }
    div {
      margin: auto;
    }
    .private {
      color: #e63946;
    }
    .link {
      border-radius: 12px;
      padding: 0.2rem 0.3rem;
      a {
        text-decoration: none;
        color: inherit;
        display: flex;
        justify-content: center;
      }
    }
    svg {
      height: 1rem;
      width: 1rem;
      fill: #1d3557;
      opacity: ${({ paywall }) => (paywall ? '0.2' : '1')};
    }
    p {
      display: flex;
      justify-content: start;
      align-items: center;
    }
  }
  .stories {
    grid-area: stories;
    svg {
      opacity: ${({ paywall }) => (paywall ? '0.2' : '1')};
    }
    img {
      opacity: ${({ paywall }) => (paywall ? '0.2' : '1')};
    }
  }
  .hashtags {
    grid-area: hashtags;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.7rem;
    height: 80%;
  }
  .charts {
    grid-area: charts;
  }
`

const UpgradeButton = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  a {
    background: rgba(29, 53, 87, 1);
    color: white;
    width: 12rem;
    height: 3rem;
    cursor: pointer;
    text-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .container {
    background-color: #457b9d;
    width: 40%;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 4px;
    color: white;
    font-family: sans-serif;
    white-space: pre-wrap;
    h2 {
      font-weight: bold;
    }
  }
`

const ExpandRowMock: FC<ExpanderComponentProps<any>> = () => {
  const user = useUserData()
  const data = MockInfluencer as Influencer

  const [paywall, setPaywall] = useState(false)
  useEffect(() => {
    const isPaywall = user.user?.paymentMode === 'free' ? true : false
    setPaywall(isPaywall)
  }, [user.loading])
  return (
    <StyledRow paywall={false}>
      {paywall && (
        <UpgradeButton>
          <div className='container'>
            <h2>SAMPLE</h2>
            <p>This is just a sample data. To unlock full access to all the metrics please upgrade to our subscription plan.</p>
            <Link to='/upgrade?expand'>View Pricing</Link>
          </div>
        </UpgradeButton>
      )}
      <ProfileCard className='profile-image' data={data} />
      <ProfileInfo className='name-info' data={data} />
      <Statistics className='stats' data={data} />
      <InfluencerCharts className='charts' data={data?.medias} />
      <div className='hashtags'>
        {data.hashtags.split(' ').map((item) => (
          <span>{item} </span>
        ))}
      </div>
      <div className='stories'>
        <InfluencerStoryContainer medias={data.medias} username={data.username} />
      </div>
    </StyledRow>
  )
}

export default ExpandRowMock
