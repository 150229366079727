import React from 'react'
import styled from 'styled-components'
import { Influencer } from '../../../types/influencer'

const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    text-decoration: underline;
    text-align: left;
    font-weight: 700;
  }
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.6rem;
    grid-area: stats;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      svg {
        height: 1rem;
        width: 1rem;
        fill: #457b9d;
        margin-right: 0.6rem;
      }
      span {
        &:hover {
          svg {
            fill: #1d3557;
          }
        }
      }
    }
  }
`

const Statistics = ({ data, className }: { data: Influencer; className: string }) => {
  console.log(data)
  return (
    <StatsContainer className={className}>
      <p>Averages</p>
      <ul>
        <li key={`engagement-${data.id}`}>
          <span title='Engagement'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
              <path d='M 9.5 5 C 5.363281 5 2 8.402344 2 12.5 C 2 13.929688 2.648438 15.167969 3.25 16.0625 C 3.851563 16.957031 4.46875 17.53125 4.46875 17.53125 L 15.28125 28.375 L 16 29.09375 L 16.71875 28.375 L 27.53125 17.53125 C 27.53125 17.53125 30 15.355469 30 12.5 C 30 8.402344 26.636719 5 22.5 5 C 19.066406 5 16.855469 7.066406 16 7.9375 C 15.144531 7.066406 12.933594 5 9.5 5 Z M 9.5 7 C 12.488281 7 15.25 9.90625 15.25 9.90625 L 16 10.75 L 16.75 9.90625 C 16.75 9.90625 19.511719 7 22.5 7 C 25.542969 7 28 9.496094 28 12.5 C 28 14.042969 26.125 16.125 26.125 16.125 L 16 26.25 L 5.875 16.125 C 5.875 16.125 5.390625 15.660156 4.90625 14.9375 C 4.421875 14.214844 4 13.273438 4 12.5 C 4 9.496094 6.457031 7 9.5 7 Z' />
            </svg>
          </span>
          {`${Math.floor(data.engagement * 100) >= 1 ? Math.floor(data.engagement * 100) : `>${1}`}%`}
        </li>
        <li key={`followers-${data.id}`}>
          <span title='Total Followers'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
              <path d='M 9 7 C 5.699219 7 3 9.699219 3 13 C 3 14.984375 3.976563 16.75 5.46875 17.84375 C 2.832031 19.152344 1 21.863281 1 25 L 3 25 C 3 21.675781 5.675781 19 9 19 C 12.324219 19 15 21.675781 15 25 L 17 25 C 17 21.675781 19.675781 19 23 19 C 26.324219 19 29 21.675781 29 25 L 31 25 C 31 21.863281 29.167969 19.152344 26.53125 17.84375 C 28.023438 16.75 29 14.984375 29 13 C 29 9.699219 26.300781 7 23 7 C 19.699219 7 17 9.699219 17 13 C 17 14.984375 17.976563 16.75 19.46875 17.84375 C 18.011719 18.566406 16.789063 19.707031 16 21.125 C 15.210938 19.707031 13.988281 18.566406 12.53125 17.84375 C 14.023438 16.75 15 14.984375 15 13 C 15 9.699219 12.300781 7 9 7 Z M 9 9 C 11.222656 9 13 10.777344 13 13 C 13 15.222656 11.222656 17 9 17 C 6.777344 17 5 15.222656 5 13 C 5 10.777344 6.777344 9 9 9 Z M 23 9 C 25.222656 9 27 10.777344 27 13 C 27 15.222656 25.222656 17 23 17 C 20.777344 17 19 15.222656 19 13 C 19 10.777344 20.777344 9 23 9 Z' />
            </svg>
          </span>
          {data.followers_temp}
        </li>
        <li key={`likes-${data.id}`}>
          <span title='Average like per post'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
              <path d='M 16.6875 3 L 16.375 3.28125 L 9.59375 10 L 5 10 L 5 26 L 21.84375 26 C 23.253906 26 24.484375 25.003906 24.78125 23.625 L 26.9375 13.625 C 27.332031 11.777344 25.886719 10 24 10 L 18.25 10 L 18.4375 9.25 C 18.640625 9.09375 18.769531 9.027344 19.0625 8.625 C 19.53125 7.984375 20 6.992188 20 5.65625 C 20 4.230469 18.710938 3 17.09375 3 Z M 17.40625 5.09375 C 17.828125 5.175781 18 5.347656 18 5.65625 C 18 6.558594 17.726563 7.117188 17.46875 7.46875 C 17.210938 7.820313 17.03125 7.90625 17.03125 7.90625 L 16.6875 8.09375 L 16.5625 8.5 L 15.96875 10.75 L 15.65625 12 L 24 12 C 24.660156 12 25.105469 12.574219 24.96875 13.21875 L 22.84375 23.21875 C 22.742188 23.6875 22.320313 24 21.84375 24 L 11 24 L 11 11.40625 Z M 7 12 L 9 12 L 9 24 L 7 24 Z' />
            </svg>
          </span>
          {data.averageLikes.toFixed(0)}
        </li>
        <li key={`following-${data.id}`}>
          <span title='Total Following'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
              <path d='M 20.21875 5 C 18.539063 5 17.15625 6.382813 17.15625 8.0625 C 17.15625 9.742188 18.539063 11.125 20.21875 11.125 C 21.902344 11.125 23.3125 9.742188 23.3125 8.0625 C 23.3125 6.382813 21.902344 5 20.21875 5 Z M 20.21875 7 C 20.820313 7 21.3125 7.464844 21.3125 8.0625 C 21.3125 8.660156 20.820313 9.125 20.21875 9.125 C 19.621094 9.125 19.15625 8.664063 19.15625 8.0625 C 19.15625 7.464844 19.621094 7 20.21875 7 Z M 12.9375 9 C 12.457031 9.058594 11.972656 9.28125 11.625 9.65625 L 8.25 13.3125 L 9.75 14.6875 L 13.09375 11.03125 C 13.128906 10.996094 13.175781 10.972656 13.21875 11 L 14.8125 12.0625 L 12.46875 15.3125 C 11.734375 16.34375 11.855469 17.761719 12.75 18.65625 L 16.28125 22.1875 L 13.375 28 L 15.625 28 L 18.09375 23.09375 C 18.480469 22.324219 18.328125 21.390625 17.71875 20.78125 L 14.1875 17.25 C 13.984375 17.046875 13.957031 16.703125 14.125 16.46875 L 16.46875 13.1875 L 17.28125 13.71875 L 18.875 16.125 C 19.246094 16.679688 19.863281 17 20.53125 17 L 25 17 L 25 15 L 20.53125 15 L 18.84375 12.4375 L 18.71875 12.28125 L 18.5625 12.15625 L 14.34375 9.34375 C 13.917969 9.058594 13.417969 8.941406 12.9375 9 Z M 12.0625 19.53125 L 10.59375 21 L 6 21 L 6 23 L 10.59375 23 C 11.121094 23 11.625 22.785156 12 22.40625 L 13.46875 20.9375 Z' />
            </svg>
          </span>
          {data.following_temp}
        </li>
        <li key={`comments-${data.id}`}>
          <span title='Average comment per post'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
              <path d='M 2 5 L 2 21 L 6 21 L 6 26.09375 L 7.625 24.78125 L 12.34375 21 L 22 21 L 22 5 Z M 4 7 L 20 7 L 20 19 L 11.65625 19 L 11.375 19.21875 L 8 21.90625 L 8 19 L 4 19 Z M 24 9 L 24 11 L 28 11 L 28 23 L 24 23 L 24 25.90625 L 20.34375 23 L 12.84375 23 L 10.34375 25 L 19.65625 25 L 26 30.09375 L 26 25 L 30 25 L 30 9 Z' />
            </svg>
          </span>
          {data.averageComments.toFixed(0)}
        </li>
        <li key={`fl-ratio-${data.id}`}>
          <span title='Following per follower'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
              <path d='M 16 6 C 14.355469 6 13 7.355469 13 9 C 13 10.644531 14.355469 12 16 12 C 17.644531 12 19 10.644531 19 9 C 19 7.355469 17.644531 6 16 6 Z M 16 8 C 16.5625 8 17 8.4375 17 9 C 17 9.5625 16.5625 10 16 10 C 15.4375 10 15 9.5625 15 9 C 15 8.4375 15.4375 8 16 8 Z M 5 15 L 5 17 L 27 17 L 27 15 Z M 16 20 C 14.355469 20 13 21.355469 13 23 C 13 24.644531 14.355469 26 16 26 C 17.644531 26 19 24.644531 19 23 C 19 21.355469 17.644531 20 16 20 Z M 16 22 C 16.5625 22 17 22.4375 17 23 C 17 23.5625 16.5625 24 16 24 C 15.4375 24 15 23.5625 15 23 C 15 22.4375 15.4375 22 16 22 Z' />
            </svg>
          </span>
          {typeof data.ratio === 'number' ? data.ratio.toFixed(2) : 'No data'}
        </li>
        <li key={`cl-ratio-${data.id}`}>
          <span title='Comments per like'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
              <path d='M 16 6 C 14.355469 6 13 7.355469 13 9 C 13 10.644531 14.355469 12 16 12 C 17.644531 12 19 10.644531 19 9 C 19 7.355469 17.644531 6 16 6 Z M 16 8 C 16.5625 8 17 8.4375 17 9 C 17 9.5625 16.5625 10 16 10 C 15.4375 10 15 9.5625 15 9 C 15 8.4375 15.4375 8 16 8 Z M 5 15 L 5 17 L 27 17 L 27 15 Z M 16 20 C 14.355469 20 13 21.355469 13 23 C 13 24.644531 14.355469 26 16 26 C 17.644531 26 19 24.644531 19 23 C 19 21.355469 17.644531 20 16 20 Z M 16 22 C 16.5625 22 17 22.4375 17 23 C 17 23.5625 16.5625 24 16 24 C 15.4375 24 15 23.5625 15 23 C 15 22.4375 15.4375 22 16 22 Z' />
            </svg>
          </span>
          {typeof data.clRatio === 'number' ? data.clRatio.toFixed(2) : 'No data'}
        </li>
        <li key={`posts-${data.id}`}>
          <span title='Total amount of posts'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
              <path d='M 25 4.03125 C 24.234375 4.03125 23.484375 4.328125 22.90625 4.90625 L 13 14.78125 L 12.78125 15 L 12.71875 15.3125 L 12.03125 18.8125 L 11.71875 20.28125 L 13.1875 19.96875 L 16.6875 19.28125 L 17 19.21875 L 17.21875 19 L 27.09375 9.09375 C 28.246094 7.941406 28.246094 6.058594 27.09375 4.90625 C 26.515625 4.328125 25.765625 4.03125 25 4.03125 Z M 25 5.96875 C 25.234375 5.96875 25.464844 6.089844 25.6875 6.3125 C 26.132813 6.757813 26.132813 7.242188 25.6875 7.6875 L 16 17.375 L 14.28125 17.71875 L 14.625 16 L 24.3125 6.3125 C 24.535156 6.089844 24.765625 5.96875 25 5.96875 Z M 4 8 L 4 28 L 24 28 L 24 14.8125 L 22 16.8125 L 22 26 L 6 26 L 6 10 L 15.1875 10 L 17.1875 8 Z' />
            </svg>
          </span>
          {data.postCount}
        </li>
      </ul>
    </StatsContainer>
  )
}

export default Statistics
