import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  padding: 1rem 2rem;
  height: 80%;
`

const News = () => {
  return (
    <Container>
      <h2>What's new</h2>
      <ul>
        <li>Launched v1 of omnifluence!</li>
        <li>Added more statistics about each influencer for premium users</li>
        <li>600 new micro influencers in the sports & fitness niche</li>
      </ul>
    </Container>
  )
}

export default News
