import { deleteUser, getAuth, reauthenticateWithCredential, reauthenticateWithRedirect } from '@firebase/auth'
import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import FullscreenSpinner from '../components/loader/fullscreen-spinner'
import { deleteUserDb, getUserData } from '../firebase/utils'
import { useUserData } from '../hooks/user'
import { DangerButton } from '../style/buttons'
import { User } from '../types/user'

const SettinsWrapper = styled.div`
  flex: 1;
`
const SettingsList = styled.div`
  .account-info {
    display: flex;
    flex-direction: column;
    & > * + * {
      border-top: 2px solid #1d3557;
    }
  }
  width: 67%;
  margin: auto;
`

const Cell = styled.div`
  display: flex;
  align-items: center;
  code {
    margin: 0 0.4rem;
  }
`

const Settings = () => {
  const userData = useUserData()
  let navigate = useNavigate()

  console.log('user data from settings', userData)

  if (userData.loading) {
    return (
      <SettinsWrapper>
        <FullscreenSpinner />
      </SettinsWrapper>
    )
  }

  const handleUserDeletion = async () => {
    if (userData && userData.user) {
      try {
        await deleteUserDb(userData.user.uid)
        await deleteUser(userData.authData!)
      } catch (e) {
        console.error('something went wrong ', e)
        // reauthenticateWithCredential(userData.authData!, getAuth())
      } finally {
        navigate('/sign-up')
      }
    } else {
      try {
        await deleteUser(userData.authData!)
      } catch (e) {
        console.error('something went wrong ', e)
        // reauthenticateWithCredential(userData.authData!, getAuth())
      } finally {
        navigate('/sign-up')
      }
    }
  }

  return (
    <SettinsWrapper>
      <SettingsList>
        <h1>Settings</h1>
        {/*       <div>
          <h2>Modules</h2>
          <ul>
            <li>Reports</li>
            <li>Database</li>
          </ul>
        </div> */}
        <div>
          <h2>Account</h2>
          <div className='account-info'>
            <Cell>
              <h3>e-mail: </h3>
              <p>
                <code>{userData.authData?.email}</code>
              </p>
            </Cell>
            <Cell>
              <h3>name: </h3>
              <p>
                <code>{userData.authData?.displayName}</code>
              </p>
            </Cell>
          </div>
          <div>
            <p>Warning all data will be lost once you delete your account. No data will be saved.</p>
            <DangerButton primary onClick={handleUserDeletion}>
              Delete Account
            </DangerButton>
          </div>
        </div>
      </SettingsList>
    </SettinsWrapper>
  )
}

export default Settings
