import React from 'react'
import styled from 'styled-components'
import right from '../../icons/right.svg'
import { useUserData } from '../../hooks/user'
import { BasicButton } from '../../style/buttons'
import { Link } from 'react-router-dom'

const LinkContainer = styled.ul`
  list-style: none;
  width: 67%;
  margin: auto;
`

const ListItem = styled.li<{ status: 'pending' | 'approved' | 'rejected' | 'closed' }>`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 0.5fr;
  align-items: center;
  margin: 0.4rem 0;
  img {
    height: 1rem;
    width: auto;
  }
  .edit {
    color: #457b9d;
    text-decoration: underline;
    cursor: not-allowed;
  }
  .close {
    color: #e63946;
  }
  .link-block {
    background-color: #f1faee;
    padding: 0.2rem 0.4rem;
    border-radius: 2px;
    font-family: monospace;
    border: ${(props) => (props.status === 'pending' ? '2px solid #FFBE0B' : '2px solid #a8dadc')};
  }
  .router {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`

const CTA = styled.div`
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
`

const Linkbee = () => {
  const userData = useUserData()
  const linkbeeData = userData.user?.links
  return (
    <div>
      <h1>Your Linkbee links</h1>
      <div>
        <h2>Active Links</h2>
        <LinkContainer>
          {linkbeeData?.map((link) => (
            <ListItem status={link.status} key={link.linkbeeUrl} title={`Status: ${link.status}`}>
              <span className='link-block'>linkbee.page/{link.linkbeeUrl}</span>
              <span className='router'>
                <img src={right} />
                <img src={right} />
                routes to
                <img src={right} />
                <img src={right} />
              </span>
              <span className='link-block'>{link.forwardUrl}</span>
              <div>
                <span
                  title={`Temporarily disabled, contact us at hey@omnifluence.io for urgent edits`}
                  className='edit'
                >
                  Edit
                </span>
                <span className='close'> X </span>
              </div>
            </ListItem>
          ))}
        </LinkContainer>
        {linkbeeData && linkbeeData?.length < 2 && (
          <CTA>
            <p>Subscribe for link management, custom variables and up to 3 additional links.</p>
            <Link to='/upgrade'>
              <BasicButton>Upgrade</BasicButton>
            </Link>
          </CTA>
        )}
      </div>
    </div>
  )
}

export default Linkbee
