import React from 'react'
import styled from 'styled-components'
import { Media } from '../../../types/influencer'
import Story from './story'
import StoryImage from './story-image'

const StoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
`
const StoryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  row-gap: 1rem;
`

const InfluencerStoryContainer = ({ medias, username }: { medias?: Media[]; username: string }) => {
  const length = medias?.length
  if (!medias || !length || length === 0) {
    return <h2>No stories found</h2>
  }
  const stories = medias.map((media) => {
    return <Story username={username} media={media} />
  })
  return (
    <StoryContainer>
      <h2>Stories</h2>
      <StoryWrapper>{stories.slice(0, 6)}</StoryWrapper>
    </StoryContainer>
  )
}

export default InfluencerStoryContainer
