import React from 'react'
import styled from 'styled-components'
import { writeUserData } from '../../firebase/utils'
import { useUserData } from '../../hooks/user'
import { BasicButton } from '../../style/buttons'
import LinkbeeSVG from './linkbeeSVG'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  & > * + * {
    border-top: 2px solid #1d3557;
    margin-top: 1rem;
  }
  h1 {
    font-size: 2rem;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`
const Header = styled.div`
  span {
    text-decoration: underline;
    font-size: 1.2rem;
    color: #457b9d;
  }
  p {
    text-align: left;
    @media (max-width: 800px) {
      width: 80%;
      margin: auto;
      font-size: 0.7rem;
    }
  }
`
const Features = styled.div`
  width: 67%;
  ul {
    list-style: none;
    text-align: left;
    h3 {
      margin-bottom: 0;
    }
    p {
      margin: 0;
      padding-left: 1rem;
    }
    span {
      text-decoration: underline;
      color: #457b9d;
    }
  }
  @media (max-width: 800px) {
    width: 90%;
    h3 {
      font-size: 0.9rem;
    }
    p {
      font-size: 0.7rem;
    }
    ul {
      padding: 0;
    }
  }
`
const CTA = styled.div`
  text-align: center;

  code {
    background-color: #cfddca;
    padding: 0.1rem 0.2rem;
  }
  margin: 1rem auto;
  .get-started {
    text-decoration: underline;
    font-size: 1.2rem;
    text-align: center;
  }
  @media (max-width: 800px) {
    margin: 2rem 0;
    p {
      margin: 0.4rem auto;
    }
  }
`

const LinkbeeInfo = () => {
  const userData = useUserData()

  // access to linkbee
  const requestAccess = async () => {
    const user = userData.user
    if (!user) return
    if (user.linkbeeAccess) {
      alert('You already have linkbee access')
      return
    } else {
      await writeUserData(user.uid, { linkbeeAccess: true })

      // to refresh page with linkbee access
      window.location.reload()
    }
  }

  return (
    <Wrapper>
      <Header>
        <h1>Linkbee</h1>
        {userData.user?.linkbeeAccess && <p style={{ color: '#a8dadc' }}>Access granted try refreshing the page.</p>}
        <p>
          Linkbee is a smart link manager that lets you take control of your links. By having all your link destinations
          saved on linkbee you can easily update your links without going back and editing old links or sending out new.
        </p>
        <CTA>
          <p className='get-started'>Get started with one link for free.</p>
          <BasicButton primary onClick={requestAccess}>
            Create Link
          </BasicButton>
        </CTA>
      </Header>
      <Features>
        <h2>Features</h2>
        <ul>
          <li>
            <h3>One link to rule them all</h3>
            <p>Update all your links in one place, without needing to share a new one.</p>
          </li>
          <li>
            <h3>Better looking</h3>
            <p>
              No more visually ugly links with tons of extra stuffing, simply: <span>linkbee.page/your-page</span>
            </p>
          </li>
          <li>
            <h3>Control your traffic flow</h3>
            <p>Dynamically update your links all over the internet at the same time.</p>
          </li>
          <li>
            <h3>Google analytics integration</h3>
            <p>Use any google utm trackers without any issues.</p>
          </li>
        </ul>
      </Features>
      <div>
        <LinkbeeSVG />
      </div>
    </Wrapper>
  )
}

export default LinkbeeInfo
