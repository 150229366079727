import { getStorage, ref } from 'firebase/storage'
import React, { useEffect, useState } from 'react'
import { useDownloadURL } from 'react-firebase-hooks/storage'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'
import { ProfileImage, SmallImage } from '../../../style/images'

const SVGContainer = styled.svg`
  height: 100%;
  width: auto;
`

const StoryImage = ({ location }: { location: string }) => {
  const storage = getStorage()
  const storageRef = ref(storage, `${location}.png`)
  const [downloadUrl, loading, error] = useDownloadURL(storageRef)
  if (loading) {
    return <Loader type='TailSpin' color='#457b9d' height={'2rem'} width={'2rem'} />
  }
  if (!downloadUrl) {
    return (
      <SVGContainer xmlns='http://www.w3.org/2000/svg' fill='#457b9d' viewBox='0 0 20 20'>
        <path d='M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zM7 6v2a3 3 0 1 0 6 0V6a3 3 0 1 0-6 0zm-3.65 8.44a8 8 0 0 0 13.3 0 15.94 15.94 0 0 0-13.3 0z' />
      </SVGContainer>
    )
  }

  return <ProfileImage src={downloadUrl} alt={`Profile picture of ${location}`} />
}

export default StoryImage
