import React from 'react'
import logo from './logo.svg'
import './App.css'
import { Routes, Route, Link } from 'react-router-dom'
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from '@firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import SignUp from './components/signup-flow/sign-up'
import RequireAuth from './components/auth/requireAuth'
import DatabaseView from './views/database'
import Settings from './views/settings'
import ListView from './views/list/list'
import Start from './views/start'
import LoginView from './views/login'
import SignupView from './views/signup'
import LinkManagerView from './views/link-manager'
import ListTable from './components/lists/listTable'
import ListTableView from './views/list/my-list'
import StartView from './views/start'
import UpgradeView from './views/upgrade'
import AppsumoPromotionSignup from './components/signup-flow/appsumo'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCRVpsdieo9FY8x-zKz7Hm-JFzEOIF39OQ',
  authDomain: 'omnifluence-app.firebaseapp.com',
  databaseURL: 'https://omnifluence-app-default-rtdb.firebaseio.com',
  projectId: 'omnifluence-app',
  storageBucket: 'omnifluence-app.appspot.com',
  messagingSenderId: '854367631947',
  appId: '1:854367631947:web:ac6531fd8893868ce82bce',
  measurementId: 'G-ZR2ZPFG96Y',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

function App() {
  const [user, loading, error] = useAuthState(getAuth())
  return (
    <div className='App'>
      <Routes>
        <Route path='/sign-up/appsumo' element={<AppsumoPromotionSignup />} />
        <Route path='/sign-up' element={<SignupView />}>
          <Route path=':goal' element={<SignupView />} />
        </Route>
        <Route path='/login' element={<LoginView />} />
        <Route
          path='/lists/my-list'
          element={
            <RequireAuth>
              <ListTableView />
            </RequireAuth>
          }
        />
        <Route
          path='/lists'
          element={
            <RequireAuth>
              <ListView />
            </RequireAuth>
          }
        />
        <Route
          path='/database'
          element={
            <RequireAuth>
              <DatabaseView />
            </RequireAuth>
          }
        />
        <Route
          path='/link-manager'
          element={
            <RequireAuth>
              <LinkManagerView />
            </RequireAuth>
          }
        />
        <Route
          path='/settings'
          element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          }
        />
        <Route
          path='/upgrade'
          element={
            <RequireAuth>
              <UpgradeView />
            </RequireAuth>
          }
        />
        <Route
          path='/subscribe'
          element={
            <RequireAuth>
              <UpgradeView />
            </RequireAuth>
          }
        />
        <Route
          path='/'
          element={
            <RequireAuth>
              <StartView />
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  )
}

export default App
