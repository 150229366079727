import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'
import { getUserData, writeUserData } from '../../firebase/utils'
import Database from '../../modules/database/databaseContainer'
import styled from 'styled-components'
import { AccentBanner } from '../../style/banners'
import UpgradeBanner from '../../components/banners/updgrade-banner'
import FullscreenSpinner from '../../components/loader/fullscreen-spinner'
import ListStart from '../../components/lists/listStart'
import { useUserData } from '../../hooks/user'

const ListsWrapper = styled.div`
  flex: 1;
  background-color: #f1faee;
  display: flex;
  justify-content: center;
`

const ListView = () => {
  const userData = useUserData()
  const { loading } = userData

  if (loading) {
    return (
      <ListsWrapper>
        <FullscreenSpinner />
      </ListsWrapper>
    )
  }

  return (
    <ListsWrapper>
      <ListStart />
    </ListsWrapper>
  )
}

export default ListView
