import React from 'react'
import styled from 'styled-components'
import Nav from '../nav/nav'
import SideNav from '../side-nav/side-nav'

const LayoutWrapper = styled.div`
  display: flex;
  min-height: calc(100vh - 2rem);
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

/**
 * Layout wrapper for all components when signed in
 */
const Layout = ({ children }: { children: JSX.Element }) => {
  return (
    <div>
      <Nav />
      <LayoutWrapper>
        <SideNav />
        {children}
      </LayoutWrapper>
    </div>
  )
}

export default Layout
