import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'
import { getUserData, writeUserData } from '../../firebase/utils'
import Database from '../../modules/database/databaseContainer'
import styled from 'styled-components'
import { AccentBanner } from '../../style/banners'
import UpgradeBanner from '../../components/banners/updgrade-banner'
import FullscreenSpinner from '../../components/loader/fullscreen-spinner'
import ListStart from '../../components/lists/listStart'
import { useUserData } from '../../hooks/user'
import ListTable from '../../components/lists/listTable'
import { useLocation } from 'react-router'

const ListTableWrapper = styled.div`
  //max-width: calc(100vw - 6rem);
  flex: 1;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1faee;
  flex-direction: column;
`

const ListTableView = () => {
  return (
    <ListTableWrapper>
      <ListTable />
    </ListTableWrapper>
  )
}

export default ListTableView
