import styled from 'styled-components'
import { Influencer, InfluencerDB } from '../../types/influencer'
import linkBlank from '../../icons/external-link.svg'
import ThumbnailImage from '../../components/table/thumbnail-image'
import { TableColumn } from 'react-data-table-component'

const Pill = styled.span<{ color?: string }>`
  border-radius: 9999px;
  background-color: ${(props) => (props.color ? props.color : 'white')};
  padding: 0.2rem 0.4rem;
  word-break: keep-all;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  font-size: 0.6rem;
  text-overflow: ellipsis;
  overflow: hidden;
`
const LinkContainer = styled.div`
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #457b9d;
    font-weight: bolder;
    img {
      height: 24px;
      width: 24px;
    }
  }
`

const colorMapper = (category: string | undefined) => {
  if (!category) {
    return '#1aaaff'
  }
  if (category.includes('lifestyle')) {
    return '#1aaaff'
  }
  if (category.includes('business')) {
    return '#F29E4C'
  }
  if (category.includes('travel')) {
    return '#2C699A'
  }
  if (category.includes('landscaping')) {
    return '#1bb978'
  }
  if (category.includes('blog')) {
    return '#048BA8'
  }
  if (category.includes('fashion')) {
    return '#0DB39E'
  }
  if (category.includes('creator')) {
    return '#F1C453'
  }
  if (category.includes('photography')) {
    return '#83E377'
  }
  if (category.includes('landscaping')) {
    return '#B9E769'
  }
  if (category.includes('design')) {
    return '#EFEA5A'
  }
  return '#16DB93'
}

export const influencerDBColumns: TableColumn<InfluencerDB>[] = [
  {
    sortable: false,
    cell: (row: InfluencerDB) => {
      return <ThumbnailImage username={row.username} />
    },
  },

  { name: 'Username', selector: (row: InfluencerDB) => row.username, sortable: true },
  {
    name: 'Engagement',
    selector: (row: InfluencerDB) => row.averageEngagement,
    sortable: true,
    right: true,
    cell: (row: InfluencerDB) => (
      <div>{`${Math.floor(row.averageEngagement * 100) >= 1 ? Math.floor(row.averageEngagement * 100) : `>${1}`}%`}</div>
    ),
  },
  {
    name: 'Category',
    //@ts-ignore
    selector: (row: InfluencerDB) => row.category_name || row?.category || '',
    sortable: true,

    cell: (row: InfluencerDB) => (
      <Pill title={row.category_name || ''} color={colorMapper(row.category_enum?.toLocaleLowerCase())}>
        {row.category_name}
      </Pill>
    ),
  },
  { name: 'Followers', selector: (row: InfluencerDB) => row.followers, sortable: true, right: true },
  {
    sortable: false,
    name: 'Link',
    cell: (row: InfluencerDB) => (
      <LinkContainer>
        <a href={`https://instagram.com/${row.username}`} target='_blank'>
          View
          <img src={linkBlank} alt='open in new window' />
        </a>
      </LinkContainer>
    ),
  },
  {
    name: 'Platform',
    selector: (row: InfluencerDB) => row.platform || 'instagram',
    sortable: true,

    cell: (row: InfluencerDB) => <Pill title={row.platform || 'instagram'}>{row.platform || 'instagram'}</Pill>,
  },
  {
    name: 'Hashtags',
    selector: (row: InfluencerDB) => row.hashtags,
    sortable: true,
    cell: (row: InfluencerDB) => (
      <div title={row.hashtags}>
        {<div style={{ display: 'none' }}>{row.hashtags}</div>}
        {!!row.hashtags ? <span style={{ fontWeight: 'bold' }}>Hover me</span> : <span></span>}
      </div>
    ),
  },
]

export const influencerColumns: TableColumn<Influencer>[] = [
  {
    sortable: false,
    cell: (row: Influencer) => {
      return <ThumbnailImage username={row.username} />
    },
  },

  { name: 'Username', selector: (row: Influencer) => row.username, sortable: true },
  {
    name: 'Engagement (%)',
    selector: (row: Influencer) => row.engagement,
    sortable: true,
    right: true,
    cell: (row: Influencer) => <div>{`${Math.floor(row.engagement * 100) >= 1 ? Math.floor(row.engagement * 100) : `>${1}`}%`}</div>,
  },
  {
    name: 'Category',
    selector: (row: Influencer) => row.categories,
    sortable: true,

    cell: (row: Influencer) => (
      <Pill title={row.categories} color={colorMapper(row.categories.toLocaleLowerCase())}>
        {row.categories}
      </Pill>
    ),
  },
  { name: 'Followers', selector: (row: Influencer) => row.followers, sortable: true, right: true },
  {
    name: 'Profile',
    selector: (row: Influencer) => row.url,
    sortable: true,
    cell: (row: Influencer) => (
      <LinkContainer>
        <a href={row.url} target='_blank'>
          View
          <img src={linkBlank} alt='open in new window' />
        </a>
      </LinkContainer>
    ),
  },
  {
    name: 'Platform',
    selector: (row: Influencer) => row.platform,
    sortable: true,

    cell: (row: Influencer) => <Pill title={row.platform}>{row.platform}</Pill>,
  },
  {
    name: 'Hashtags',
    selector: (row: Influencer) => row.hashtags,
    sortable: true,
    cell: (row: Influencer) => (
      <div title={row.hashtags}>
        {<div style={{ display: 'none' }}>{row.hashtags}</div>}
        {!!row.hashtags ? <span style={{ fontWeight: 'bold' }}>Hover me</span> : <span></span>}
      </div>
    ),
  },
]
