import React from 'react'
import styled from 'styled-components'
import { useUserData } from '../../hooks/user'
import MiniList from '../lists/miniList'
import FullscreenSpinner from '../loader/fullscreen-spinner'
import News from './news'

const StartWrapper = styled.div`
  height: 90%;
  width: 75%;
  border-radius: 8px;
  align-self: center;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  flex-direction: column;
  text-align: left;
  h1 {
    text-align: center;
    margin-bottom: 4rem;
  }
  h2,
  p {
    margin: 0.2rem;
  }
  padding: 0 8rem;
  .cell2 {
    border-top: 1px solid #1d3557;
    padding-top: 2rem;
  }
  a {
    color: #457b9d;
  }
  .buttonlink {
    color: inherit !important;
    text-decoration: none !important;
  }
  @media (max-width: 800px) {
    width: 100%;
    box-shadow: none;
  }
`
const CTA = styled.div`
  display: flex;
  justify-content: space-between;
  h2 {
    margin-right: 2rem;
  }
`

const Cell = styled.div`
  max-width: 50%;
`
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const InfluencerBox = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px inset;
  border-radius: 6px;
  padding: 1rem 2rem;
  margin: 2rem;
  height: 40%;
  width: 60%;
`
const LinkbeeBox = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px inset;
  border-radius: 6px;
  padding: 1rem 2rem;
  margin: 2rem;
  height: 40%;
  width: 60%;
  color: #a8dadc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const RightContainer = styled.div`
  display: flex;
  max-width: 33%;
  @media (max-width: 800px) {
    width: 100%;
    max-width: 100%;
  }
`

const Start = () => {
  const userData = useUserData()
  // fetch all the lists the user has

  const lists = userData.user?.lists

  if (userData.loading) {
    return (
      <StartWrapper>
        <FullscreenSpinner />
      </StartWrapper>
    )
  }

  return (
    <StartWrapper>
      <h1>Welcome!</h1>
      <Container>
        <LeftContainer>
          <InfluencerBox>
            <h2>Influencers</h2>
            <MiniList listItems={lists} />
          </InfluencerBox>
          <LinkbeeBox>
            <h2>Linkbee</h2>
            <p>More info coming soon.</p>
          </LinkbeeBox>
        </LeftContainer>
        <RightContainer>
          <News />
        </RightContainer>
      </Container>
    </StartWrapper>
  )
}

export default Start
