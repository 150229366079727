import React from 'react'
import { BasicButton } from '../../../style/buttons'
import { Influencer } from '../../../types/influencer'
import FullImage from './full-image'

const ProfileCard = ({ data, className }: { data: Influencer; className: string }) => {
  return (
    <div className={className}>
      <FullImage username={data.username} />
      <div>
        <div className='link'>
          <a target='_blank' href={`${data.url}`}>
            <BasicButton primary>Open In instagram</BasicButton>
          </a>
        </div>
        <p>
          Instagram verified{' '}
          {data.verified ? (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
              <path d='M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z' />
            </svg>
          ) : (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
              <path d='M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z' />
            </svg>
          )}
        </p>
        <p>
          Professional account{' '}
          {data.professional_account ? (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
              <path d='M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z' />
            </svg>
          ) : (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
              <path d='M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z' />
            </svg>
          )}
        </p>
        {data.business_email && <p>e-mail: {data.business_email}</p>}
        {data.business_account && (
          <p>
            Bussiness account{' '}
            {data.business_account && (
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
                <path d='M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z' />
              </svg>
            )}
          </p>
        )}
        {data.private && <p className='private'>Private account</p>}
      </div>
      <div></div>
    </div>
  )
}

export default ProfileCard
