import React from 'react'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'
import Table from '../../components/table/table'
import { useDatabase } from '../../firebase/utils'
import { useUserData } from '../../hooks/user'
import { influencerColumns, influencerDBColumns } from './dataColumns'

const TableWrapper = styled.div`
  margin: 0 2rem;
  padding-left: 1rem;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  width: 84vw;
`

const Database = () => {
  const user = useUserData()
  // will be the permanent database
  const id = '1c5zDt-Kyy2rFFOXxSyv-1GWI38YWkaOB73VJiaUP8bY/influencers'

  const { data, loading, error } = useDatabase(id)
  const title = 'Database'

  if (loading) {
    return (
      <div>
        <Loader type='Hearts' color='#E63946' />
        <div>
          Please Wait. <br /> (first load can take some time).
        </div>
      </div>
    )
  }

  const columns = influencerDBColumns
  return (
    <TableWrapper>
      <Table columns={columns} data={data} progressPending={loading} title={title} />
    </TableWrapper>
  )
}

export default Database
