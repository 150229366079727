import React from 'react'
import styled from 'styled-components'

export const AccentBanner = styled.div<{ primary?: boolean }>`
  min-height: 4rem;
  background-color: #a8dadc;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-top: 4px solid #1d3557;
  margin: 1rem 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.6rem;
  h3 {
    margin: 0;
  }
  p {
    margin: 0;
  }
  img {
    height: 1.4rem;
    width: 1.4rem;
  }
  .icon {
    margin: 0.2rem;
  }
  min-width: 50%;
`
