import React from 'react'
import { Link } from 'react-router-dom'
import { List } from '../../types/user'
import rightIcon from '../../icons/right.svg'
import instagramIcon from '../../icons/instagram.svg'
import styled from 'styled-components'

const ListContainer = styled.ul`
  text-decoration: none;
  margin: 2rem 0;
  padding: 0;
`

const ListItem = styled.li`
  max-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem;
  div {
    display: flex;
    align-items: center;
  }
  img {
    height: 1rem;
    width: 1rem;
  }
  a {
    margin-left: 0.6rem;
    display: flex;
    align-items: center;
    color: inherit;
    &:hover {
      font-weight: bolder;
    }
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  }
`

const MiniList = ({ listItems }: { listItems: List[] | undefined }) => {
  if (!listItems) {
    return null
  }
  return (
    <ListContainer>
      {listItems.map((list) => (
        <ListItem>
          <div>
            <img src={list.type === 'instagram' ? instagramIcon : undefined} />
            {list.name}
          </div>
          <Link to='/lists/my-list' state={{ listId: list.id, listName: list.name }}>
            View <img src={rightIcon} />
          </Link>
        </ListItem>
      ))}
    </ListContainer>
  )
}

export default MiniList
