import React from 'react'
import styled from 'styled-components'

export const BasicButton = styled.button<{ primary?: boolean }>`
  background: ${(props) => (props.primary ? '#1d3557' : 'white')};
  color: ${(props) => (props.primary ? 'white' : '#1d3557')};
  font-size: 1em;
  padding: 0.25em 1em;
  border: 2px solid #1d3557;
  border-radius: 3px;
  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  cursor: pointer;
`

export const DangerButton = styled.button<{ primary?: boolean }>`
  background: ${(props) => (props.primary ? '#e63946' : 'white')};
  color: ${(props) => (props.primary ? 'white' : '#e63946')};
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid #e63946;
  border-radius: 3px;
  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  cursor: pointer;
`
