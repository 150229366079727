import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import slugify from 'slugify'
import styled from 'styled-components'
import { writeUserData } from '../../firebase/utils'
import { useUserData } from '../../hooks/user'
import { BasicButton } from '../../style/buttons'
import { LinkbeeLink } from '../../types/user'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: #f1faee;
  @media (max-width: 800px) {
    min-height: 100vh;
    padding-bottom: 2rem;
  }
`

const Form = styled.form`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  padding: 0 4rem;
  width: 50%;
  overflow-x: hidden;
  @media (max-width: 800px) {
    width: 90%;
    padding: 0;
  }
`
const FormItem = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  label {
    span {
      font-size: 0.8rem;
      color: #457b9d;
      display: none;
    }
  }
  .input-container {
    font-family: monospace;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 2rem 0;
    input {
      width: 50%;
      height: 2rem;
      padding: 0.6rem;
      color: #1d3557;
      background: #fff;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid #a8dadc;
      outline: none;
      box-sizing: border-box;
      filter: none !important;
      transition: all 0.3s ease;
      &:focus {
        border: 2px solid #a8dadc;
      }
    }
  }
`

const Header = styled.div`
  h1,
  p {
    margin: 1rem;
  }
  margin-bottom: 2rem;
`

const Result = styled.div`
  border-top: 2px solid black;
  margin-top: 2rem;
  padding-top: 2rem;
  text-align: left;
  span {
    text-decoration: underline;
    color: #457b9d;
  }
`

const CreateLink = () => {
  const userData = useUserData()
  let navigate = useNavigate()

  const [linkbeeInput, setLinkbeeInput] = useState('')
  const [forwardInput, setForwardInput] = useState('')

  const [loading, toggleLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = async () => {
    toggleLoading(true)
    const linkbeeUrl = slugify(linkbeeInput, { lower: true })
    const forwardUrl = forwardInput
    const ownerId = userData.user!.uid

    if (linkbeeUrl.length < 1 || forwardUrl.length < 1) {
      alert('Link is to short')
      toggleLoading(false)
      return
    }
    const linkbeeLink: LinkbeeLink = {
      linkbeeUrl,
      forwardUrl,
      ownerId,
      status: 'pending',
    }

    let links = userData.user?.links || []

    links.push(linkbeeLink)

    await writeUserData(userData.user!.uid, { links })
    setSubmitted(true)
  }

  if (submitted) {
    return (
      <Wrapper>
        <Header>
          <h1>Success!</h1>
          <p>Your link has been submitted, please note that it can take up to 24hours for the changes to propagate.</p>
        </Header>
        <Link to='/'>
          <BasicButton primary>Back to start</BasicButton>
        </Link>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Header>
        <h1>Create your link</h1>
        <p>Get started by creating a linkbee link and decide where that link will lead</p>
      </Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <FormItem>
          <label>
            Select your linkbee path: <span>this is how the link will appear to others</span>
          </label>
          <div className='input-container'>
            linkbee.page/
            <input type='text' value={linkbeeInput} onChange={(e) => setLinkbeeInput(e.target.value)} />
          </div>
        </FormItem>
        <FormItem>
          <label>
            Select your link destination: <span>this where the people that click your link will end up</span>
          </label>
          <div className='input-container'>
            https://
            <input type='text' value={forwardInput} onChange={(e) => setForwardInput(e.target.value)} />
          </div>
        </FormItem>
        <div>
          {forwardInput.length > 1 && (
            <Result>
              <p>
                Clicking on <span>linkbee.page/{slugify(linkbeeInput, { lower: true })}</span>
              </p>
              <p>
                will take you to <span>https://{forwardInput}</span>
              </p>
            </Result>
          )}
        </div>
        <div>{!loading && <BasicButton primary>Create this link</BasicButton>}</div>
      </Form>
    </Wrapper>
  )
}

export default CreateLink
