import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import HomeIcon from '../../icons/home.svg'
import ReportIcon from '../../icons/report.svg'
import StackIcon from '../../icons/stack.svg'
import LinkIcon from '../../icons/link.svg'

const SideNavWrapper = styled.div`
  // position: absolute;
  //top: 0;
  //left: 0;
  width: 4rem;
  //overflow-y: hidden;
  background-color: #a8dadc;
  @media (max-width: 800px) {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
  }
`

const SideNavList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  justify-content: center;
  list-style: none;
  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  li {
    padding: 2rem 0;
    &:hover {
      background-color: #f1faee;
      box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    }
    @media (max-width: 800px) {
      padding: 0;
    }
  }
  a {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: inherit;
    font-size: 0.7rem;
    @media (max-width: 800px) {
      flex-direction: row;
      justify-content: start;
    }
  }
  img {
    height: 100%;
    width: 1.4rem;
  }
`

const SideNav = () => {
  return (
    <SideNavWrapper>
      <SideNavList>
        <li>
          <Link to='/'>
            <img src={HomeIcon} />
            Home
          </Link>
        </li>
        <li>
          <Link to='/lists'>
            <img src={ReportIcon} />
            Lists
          </Link>
        </li>
        <li>
          <Link to='/database'>
            <img src={StackIcon} />
            Influencer Database
          </Link>
        </li>
        <li>
          <Link to='/link-manager'>
            <img src={LinkIcon} />
            Linkbee
          </Link>
        </li>
      </SideNavList>
    </SideNavWrapper>
  )
}

export default SideNav
