import React from 'react'
import styled from 'styled-components'
import icon from '../../icons/linkbee-showcase-store.svg'

const Img = styled.img`
  @media (max-width: 800px) {
    max-width: 100%;
  }
`

const LinkbeeSVG = () => {
  return <Img src={icon} />
}

export default LinkbeeSVG
