import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useUserData } from '../../hooks/user'
import { BasicButton } from '../../style/buttons'
import MiniList from './miniList'

const ListStartWrapper = styled.div`
  height: 80%;
  width: 70%;
  border-radius: 8px;
  align-self: center;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  flex-direction: column;
  text-align: left;
  h1 {
    text-align: center;
    margin-bottom: 4rem;
  }
  h2,
  p {
    margin: 0.2rem;
  }
  padding: 0 8rem;
  .cell2 {
    border-top: 1px solid #1d3557;
    padding-top: 2rem;
  }
  a {
    color: #457b9d;
  }
  .buttonlink {
    color: inherit !important;
    text-decoration: none !important;
  }
`
const CTA = styled.div`
  display: flex;
  justify-content: space-between;
  h2 {
    margin-right: 2rem;
  }
`

const Cell = styled.div`
  max-width: 50%;
`

const ListStart = () => {
  const userData = useUserData()
  // fetch all the lists the user has

  const lists = userData.user?.lists

  return (
    <ListStartWrapper>
      <h1>Your Lists</h1>
      <Cell className='cell1'>
        <CTA>
          <h2>Lists</h2>
          <a className='buttonlink' target='_blank' href='https://www.theinfluencerlist.co/pricing?from=app'>
            <BasicButton>Buy more</BasicButton>
          </a>
        </CTA>
        <MiniList listItems={lists} />
      </Cell>
      <Cell className='cell2'>
        <h2>Custom lists</h2>
        <p>
          You don't have any custom lists. <br /> To order one click{' '}
          <a target='_blank' href='https://www.theinfluencerlist.co/custom-list?from=app'>
            here
          </a>
        </p>
      </Cell>
    </ListStartWrapper>
  )
}

export default ListStart
