import React, { FC, useEffect, useState } from 'react'
import { ExpanderComponentProps } from 'react-data-table-component'
import { useUserData } from '../../../hooks/user'
import { Influencer } from '../../../types/influencer'
import ExpandRow from './expandRow'
import ExpandRowMock from './expandRowMock'

const ExpandRowContainer: FC<ExpanderComponentProps<any>> = ({ data }: { data: Influencer }) => {
  const user = useUserData()
  const [hasFullAccess, setFullAccess] = useState(false)
  useEffect(() => {
    if (user) {
      const access = user.user?.paymentMode === 'database' ? true : false
      setFullAccess(access)
    }
  }, [user.loading])

  if (!hasFullAccess) {
    return <ExpandRowMock data={data} />
  }

  return <ExpandRow data={data} />
}

export default ExpandRowContainer
