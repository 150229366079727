import React from 'react'
import { AccentBanner } from '../../style/banners'
import InfoIcon from '../../icons/info-circle.svg'
import { BasicButton } from '../../style/buttons'
import { Link } from 'react-router-dom'

const UpgradeBanner = ({ headline, text, link = '/upgrade' }: { headline?: string; text?: string; link?: string }) => {
  return (
    <AccentBanner>
      <div className='icon'>
        <img src={InfoIcon} />
      </div>
      <div className='text'>
        <h3>{headline}</h3>
        <p>{text}</p>
      </div>
      <div>
        <a href={link}>
          <BasicButton primary>Upgrade</BasicButton>
        </a>
      </div>
    </AccentBanner>
  )
}

export default UpgradeBanner
