import { ChartData, ChartDataset } from 'chart.js'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Media } from '../../../types/influencer'
import { LineChart } from '../../charts/line-chart'

const ChartContainer = styled.div`
  height: 400px;
  width: 80%;
  margin: auto;
`

const InfluencerCharts = ({ data, className }: { data?: Media[]; className?: string }) => {
  // Create the dataset
  const [likesDataset, setLikesDataset] = useState()
  const [commentsDataset, setCommentsDataset] = useState()
  console.log('medias', data)
  if (!data) {
    return <h2>Problem loading chart</h2>
  }

  const likesData = data.map((item) => {
    return {
      x: new Date(item.timestamp * 1000).toTimeString(),
      y: item.likes,
    }
  })
  const commentsData = data.map((item) => {
    return {
      x: new Date(item.timestamp * 1000).toTimeString(),
      y: item.comments,
    }
  })

  const likesChartData: ChartData<'line', any[], string> = {
    datasets: [
      {
        label: 'Likes',
        data: likesData,
        borderColor: 'rgb(116, 0, 184)',
        backgroundColor: 'rgb(116, 0, 184, 0.8)',
        cubicInterpolationMode: 'monotone',
        tension: 0.4,
      },
    ],
  }
  const commentsChartData: ChartData<'line', any[], string> = {
    datasets: [
      {
        label: 'Comments',
        data: commentsData,
        borderColor: 'rgb(94, 96, 206)',
        backgroundColor: 'rgb(94, 96, 206, 0.8)',
        cubicInterpolationMode: 'monotone',
        tension: 0.4,
      },
    ],
  }
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Charts ',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        type: 'category',
        ticks: {
          //@ts-ignore
          callback: (value, index, ticks) => {
            // add one to not have 0 as first
            return `${index + 1}`
          },
        },
      },
    },
  }
  return (
    <ChartContainer className={className}>
      <LineChart dataset={likesChartData} options={options} />
      <LineChart dataset={commentsChartData} options={options} />
    </ChartContainer>
  )
}

export default InfluencerCharts
