import React, { FC, useState } from 'react'
import DataTable from 'react-data-table-component'
import styled from 'styled-components'
import { Influencer } from '../../types/influencer'
import ExpandRowContainer from './expanded-row/expandContainer'
import ExpandRow from './expanded-row/expandRow'

const CustomDataTable = styled(DataTable)`
  max-height: 80vh;
  .rdt_Table {
    max-height: 80vh;
  }
`
const MessageButton = styled.span`
  background-color: #1aaaff;
  padding: 0.2rem 0.6rem;
  border-radius: 4px;
  color: white;
  &:hover {
    cursor: not-allowed;
  }
`

const Table: FC<{ data: Influencer[] | any; columns: any; progressPending: boolean; className?: any; title: string }> = ({
  data,
  columns,
  progressPending,
  className,
  title,
}) => {
  const [selectedRows, updateSelectedRows] = useState<[] | Influencer[]>([])
  const handleSelectChange = ({ selectedRows }: any) => {
    updateSelectedRows(selectedRows)
  }
  if (!data) {
    return null
  }
  return (
    <CustomDataTable
      className={className}
      title={title}
      data={data}
      columns={columns}
      selectableRows
      pagination
      onSelectedRowsChange={handleSelectChange}
      highlightOnHover
      fixedHeader
      dense={false}
      paginationPerPage={50}
      paginationRowsPerPageOptions={[10, 50, 100]}
      progressPending={progressPending}
      contextActions={<MessageButton key='message'>Message</MessageButton>}
      striped={true}
      expandableRows
      expandableRowsComponent={ExpandRowContainer}
    />
  )
}

export default Table
