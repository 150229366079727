import { getAuth } from '@firebase/auth'
import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getUserData } from '../firebase/utils'
import { User } from '../types/user'

export const useUserData = () => {
  // auth user object
  const [authUser, authLoading, authError] = useAuthState(getAuth())
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    console.log('loading user')
    if (authUser) {
      const fetchUserData = async () => {
        const response = await getUserData(authUser.uid)
        setUser(response)
        setLoading(false)
      }

      fetchUserData()
    }
  }, [authLoading])
  return {
    authData: authUser,
    user,
    loading,
  }
}
