import React from 'react'
import styled from 'styled-components'
import { Influencer } from '../../../types/influencer'

const ProfileInfoWrapper = styled.div`
  .bio {
    white-space: pre-wrap;
    margin-left: 2rem;
  }
`

const ProfileInfo = ({ data, className }: { data: Influencer; className: string }) => {
  return (
    <ProfileInfoWrapper className={className}>
      <div>
        <p>
          <b>Username: </b>
          {data.username}
        </p>
        {data.full_name && (
          <p>
            <b>Name: </b>
            {data.full_name}
          </p>
        )}
      </div>
      <div className='bio'>
        <b>Bio: </b>
        {data.biography}
      </div>
    </ProfileInfoWrapper>
  )
}

export default ProfileInfo
