import React from 'react'
import styled from 'styled-components'
import Start from '../components/start/start'

// This view will be the default view when a user is logged in
// will also show some info about each module they are subscribed too
// ie links, reports, db, crm etc

const StartWrapper = styled.div`
  flex: 1;
  background-color: #f1faee;
  display: flex;
  justify-content: center;
`
const StartView = () => {
  return (
    <StartWrapper>
      <Start />
    </StartWrapper>
  )
}

export default StartView
