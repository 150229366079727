import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BasicButton } from '../../style/buttons'

const LoginWrapper = styled.div`
  background-color: white;
  width: 33%;
  height: 60%;
  border-radius: 6px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    width: 100%;
    border-radius: 0;
  }
`
const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 67%;
  height: 80%;
  div {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0.8rem 0;
  }
  input {
    flex: 1;
    width: 100%;
    height: 2rem;
    padding: 0.6rem;
    color: #1d3557;
    background: #fff;
    border-radius: 6px;
    border: 2px solid #a8dadc;
    box-sizing: border-box;
    filter: none !important;
    transition: all 0.3s ease;
    &:focus {
      border: 2px solid #457b9d;
      outline: none;
    }
  }
`
const GoogleSignInWrapper = styled.div`
  margin-top: 2rem;
  padding: 0.2rem 0;
  width: 67%;
`
const CustomLink = styled(Link)`
  color: #3b3a3a;
  font-weight: bolder;
`

const ErrorWrapper = styled.p`
  color: #e63946;
`

const Login = () => {
  const provider = new GoogleAuthProvider()
  const auth = getAuth()
  let navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(auth)
  const googleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider)
      const credentials = GoogleAuthProvider.credentialFromResult(result)
      const token = credentials?.accessToken
    } catch (e) {
      console.error('google login error', e)
    } finally {
      navigate(`/`)
    }
  }
  const emailSignIn = async (email: string, password: string) => {
    console.log('triggered')
    try {
      const result = await signInWithEmailAndPassword(email, password)
      console.log('signin result', result)
    } catch (e) {
      console.error('email singup error', e)
    } finally {
      navigate(`/`)
    }
  }

  return (
    <LoginWrapper>
      <LoginForm>
        <h1>Log in</h1>
        {error && <ErrorWrapper>{error.message}</ErrorWrapper>}
        <div>
          <label>Email adress</label>
          <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div>
          <label>Password</label>
          <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div>
          <BasicButton primary onClick={() => emailSignIn(email, password)}>
            Log in
          </BasicButton>
        </div>
      </LoginForm>
      <GoogleSignInWrapper>
        <BasicButton onClick={googleSignIn}>Sign in with google</BasicButton>
      </GoogleSignInWrapper>
      <p>
        Need an account? <CustomLink to='/sign-up'>Sign up</CustomLink>
      </p>
    </LoginWrapper>
  )
}

export default Login
