import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import SettingsIcon from '../../icons/user-cog.svg'
import logo from '../../icons/logo/logo.svg'
import UserModal from './actionsModal'
import { StyledLogo } from '../../style/logo'

const NavWrapper = styled.nav`
  width: 100%;
  height: 2rem;
  background-image: linear-gradient(to left, #1d3557, #264669, #30577a, #3a698c, #457b9d);
  .settings {
    height: 1rem;
    width: 2rem;
    border-radius: 4px;
    cursor: pointer;
    border: 2px solid #1d3557;
    &:hover {
      border: 2px solid #f1faee;
    }
  }
  svg {
    width: 3rem;
    height: 3rem;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1rem;
    margin-left: 1rem;
    height: 100%;
    color: #f1faee;
    span {
      position: relative;
    }
  }
`

const Nav = () => {
  const [showModal, toggleModal] = useState(false)
  return (
    <NavWrapper>
      <div>
        <span className='logo'>
          <StyledLogo color='#F1FAEE' />
        </span>
        <span>Omnifluence</span>
        <span>
          <img className='settings' src={SettingsIcon} onClick={() => toggleModal(!showModal)} />
          {showModal && <UserModal />}
        </span>
      </div>
    </NavWrapper>
  )
}

export default Nav
