import React from 'react'
import styled from 'styled-components'

export const ProfileImageThumbnail = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 999999px;
`

export const ProfileImage = styled.img`
  width: auto;
  height: 100%;
  border-radius: 8px;
`
export const SmallImage = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 8px;
`

