import React, { useState } from 'react'
import styled from 'styled-components'
import BrowserExample from '../../images/browser-example3.png'
import Down from '../../icons/down.svg'
import CodeRedeemer from './codeRedeemer'
import AppsumoSignup from './appsumo-sign-up'

const SignupWrapper = styled.div`
  background-image: linear-gradient(to right bottom, #a8dadc, #bae3dd, #cdebdf, #e0f2e5, #f1faee);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: start;
  align-items: start;
  .logo {
    margin-top: 4rem;
    svg {
      height: 200px;
    }
  }
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    width: 100%;
    margin-bottom: 4rem;
  }
`

const FeatureContainer = styled.div`
  min-height: 100vh;
  width: 66%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 67%;
    height: auto;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`

const ImageContainer = styled.div``
const FeatureInfo = styled.div`
  background-color: white;
  height: 80%;
  width: 80%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 1.6rem;
  }
  @media (max-width: 800px) {
    width: 80%;
    padding: 0 0.4rem;
    justify-content: space-between;
    h1 {
      font-size: 1rem;
    }
  }
`
const ReadMore = styled.div`
  @media (min-width: 800px) {
    display: none;
  }
  width: 100%;
  img {
    width: 2rem;
    margin: auto;
  }
`
const OfferList = styled.ul`
  list-style: none;

  width: 67%;
  margin: 1rem auto;
  p {
    margin: 0;
    font-weight: 700;
  }
  li {
    display: flex;
    align-items: center;
    margin: 0.2rem 0;
    svg {
      height: 1rem;
      width: 2rem;
      fill: green;
      stroke: green;
      stroke-width: 0.2rem;
    }
  }
`
const AppsumoPromotionSignup = () => {
  const [code, setCode] = useState()
  const [validated, setValidation] = useState(false)

  return (
    <SignupWrapper>
      {validated ? <AppsumoSignup /> : <CodeRedeemer isValidCode={setValidation} />}
      <FeatureContainer>
        <FeatureInfo>
          <h1>Start Your Influencer Marketing Journey</h1>
          <p>Gain access to information about 2000 instagram micro influencers.</p>
          <ImageContainer>
            <img src={BrowserExample} />
          </ImageContainer>
          <p>
            Search through our database to find micro influencers that are active in the category your customers are
            interested in.
          </p>
          <OfferList>
            <p>Includes:</p>
            <li>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
                <path d='M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z' />
              </svg>
              2000 Instagram Micro Influencers
            </li>
            <li>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
                <path d='M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z' />
              </svg>
              +500 New Each Month
            </li>
            <li>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
                <path d='M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z' />
              </svg>
              Engagement, Follower and Hashtag data
            </li>
            <li>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
                <path d='M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z' />
              </svg>
              Categories the influencers are active in
            </li>
            <li>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
                <path d='M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z' />
              </svg>
              One google integrated, campaign management link
            </li>
          </OfferList>
          <ReadMore>
            <img src={Down} />
          </ReadMore>
        </FeatureInfo>
      </FeatureContainer>
    </SignupWrapper>
  )
}

export default AppsumoPromotionSignup
