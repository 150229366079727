import { getAuth } from '@firebase/auth'
import React from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Navigate, useLocation } from 'react-router'
import Layout from '../layout/layout'
import FullscreenSpinner from '../loader/fullscreen-spinner'

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const [user, loading, error] = useAuthState(getAuth())
  let location = useLocation()

  if (loading) {
    return <FullscreenSpinner />
  }
  if (!user) {
    return <Navigate to='/login' state={{ from: location }} />
  }
  return <Layout>{children}</Layout>
}

export default RequireAuth
