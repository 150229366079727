export const promotionalCodes = [
  'aerasfdaer',
  'hk6ij6n0vt',
  '1vzu8cezvu',
  'tm2gfnzvwk',
  'dlunqjz1j7',
  '17mvqerj4t',
  'u9kbjqihbx',
  'ilmpqbfs6u',
  'fr1sw4ieac',
  '5irbqrlewh',
  'zy5gfsh4ty',
  'nuqhq0pf8e',
  'z7n4y2vusp',
  '88iihxvdpu',
  'sr1z7zlc31',
  '4bp9b88fea',
  'saj64qwf53',
  'r8ihuvcfao',
  'pf9vog43cm',
  '3vywkjkei1',
  '5orpgzlyhb',
  'h047qp3j0t',
  '5njt2egnwy',
  'q1qlhcv6xc',
  '3fe6wark1l',
  '1ypab9z3x6',
  'q0bjpqs0dy',
  'xgcwps9k29',
  'x8ypk97opl',
  'trvqpu6yuq',
  'cllntrjt9o',
  '9qpx813gkn',
  '5or97583qg',
  'lwf7qfmnye',
  '0p1bp60eok',
  'x2ibksji14',
  'belwrwcuia',
  'byghwmul6m',
  'opiv6h7dps',
  '6png8h6rfk',
  'yi228m92hc',
  'cpnfrtqsux',
  '0nfjcvo4xt',
  'sy5mraa8pt',
  'ofmxum86nb',
  '6tvtjsevxx',
  'kzl1td062n',
  'u9vridpl12',
  '56ov7r4fjd',
  'qmr39gou20',
  'rz0f6wv5iz',
  'l3e039cpwd',
  'n2iij8bbna',
  'r58vfk1ejf',
  'xcgup3qgkp',
  '75rifg17cr',
  'hdxxq34uwp',
  'q91c7l936c',
  'xf5ub17fn2',
  'ow7pcbsa9d',
  'lmejvrmr9j',
  'fi74wu2kp0',
  'kueeuprv36',
  'wiabjbxhsn',
  'aqi23o4swb',
  'ho0jledrix',
  '8w133h6hxo',
  'dizdm2me2k',
  'ibim8mj25c',
  'phxgfcu6th',
  'qxjitnnfj6',
  'ucogpuddpc',
  'yjc8mcuhig',
  '34slbboe5x',
  'vi1gvepyg4',
  'i92vaktxcg',
  'qt9uds7ava',
  '0nsdp4oppb',
  'tqlb1crqxj',
  '8kiomvnolq',
  'kddg8qpg8o',
  'oq20tai5yi',
  'v3kzw7it1n',
  'i0syhu4omp',
  'u29mwxcxfh',
  'vf2nc16sbu',
  'lm8gcs6jx0',
  'lxhlqg52cn',
  'u5sgzklnbn',
  'da5xdqb5np',
  'qvn96cj1bg',
  'w2q3tpvxmw',
  '9yl8gfcbme',
  'cvpu40sn1y',
  'btlpzk3g5w',
  'xn2madks0a',
  'lifqoqpoau',
  '1e6k2h6c7i',
  'dvj1a3lmf8',
  'swuf6jkfek',
  'o2vvn3e4cy',
  'dd660brhck',
  'wxi0b350jw',
  'wp0mmh47w6',
  'le0m2d028m',
  '1o8r4pxkbj',
  '3n0bozbl2z',
  'h20nftt4mc',
  'd1lk03mipx',
  'cqpva1fkq5',
  '0vs5fi2yzi',
  'ok862ps7p1',
  '6ve81rtatf',
  '0t4ht5dx78',
  'uo04riysa6',
  'i255fvil4k',
  'bxotx9ltsd',
  'fo0oz7g0u1',
  'xru63sclxz',
  'xoi0w40mim',
  '0wef6mj7eg',
  'c93uufn3ux',
  'n393rmdpsb',
  'q1vzmazd89',
  'egddzoufq6',
  'pe0iikkm3h',
  'b9bf377sir',
  'htuevz32bs',
  '6q6rr2orqu',
  'v4g8mp00zy',
  'almxlo9daq',
  'lqyoh8jjkb',
  '3pnuoxsfsy',
  'rwzajlmxia',
  '0fb5shwwjo',
  'ctbzhxz8qv',
  'hxt7cotmz6',
  'i1c41re0rk',
  'wljl4i8c65',
  'h05zo4yon0',
  'deh31bvvi9',
  'skn6yx9llu',
  'kbjvs591ju',
  'a87vjrxaow',
  '6ud07gredm',
  'xbs2l830q5',
  'mx0zr7bwhr',
  'fdjic3vub2',
  'klwsmjr8kh',
  '9qfc8ybdo4',
  'lavjihxhcf',
  '6p62or2xt7',
  'dof8siws3p',
  '3alty33ofx',
  'ykjdpgux9b',
  '97lnw11msl',
  'm6eqj74z37',
  '1qqhk9fhcd',
  '53xvqo9mpt',
  'esbgcxjkte',
  'ai77usksko',
  'byu6b74zjg',
  'ogh28e4bs0',
  'zoqswhqjiz',
  'cymo52633l',
  '6osdnaevf6',
  'c1lr2i56ua',
  'qakj2dwtal',
  'b2nrq9jsk6',
  'ijnki79h29',
  '1i7xaq01my',
  '5vuuq8t2z2',
  'dodycfpjqb',
  'ifglkza080',
  '5ijq10jj5q',
  'aywvpps2lf',
  'h7epxvhhsh',
  'wza0no6c66',
  'o85y0zr9y6',
  'nakk4x2mp7',
  'ruyf1zn1go',
  'o0fpyfdfq9',
  '2usuc0aiit',
  '14u9pg3d1f',
  'btggfvjpwb',
  'o0i9la16ie',
  'mx8tr9zm4t',
  'h9fbkb440u',
  'ubritsbiuz',
  'zhvnxvryxy',
  'ihnzxjinjk',
  'x5cabdc70n',
  'i3l1gw1o8q',
  'r284ynv4xb',
  '9i6av4buyy',
  '0oq65k7qlh',
  '8pglpzcmjc',
  'gjdtiz6419',
  'z1srbi0jp0',
  '3ckqkiq9sx',
]
