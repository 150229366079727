import React from 'react'
import styled from 'styled-components'
import { Media } from '../../../types/influencer'
import StoryImage from './story-image'

const StoryContainer = styled.li<{ bg?: string }>`
  background-image: ${(props) => props.bg && `url('${props.bg}')`};
  background-size: contain;
  background-repeat: no-repeat;
  height: 5rem;
  display: flex;
  div {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
  }
  ul {
    display: flex;
    justify-content: start;
    align-items: center;
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
    svg {
      height: 1rem;
      width: 1rem;
      fill: #457b9d;
    }
    li {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
    }
  }
  p {
    font-size: 0.6rem;
    overflow: hidden;
    text-align: left;
    margin-top: 0.4rem;
  }
`
const SVGContainer = styled.svg`
  height: 100%;
  width: auto;
`

const Story = ({ media, username }: { media: Media; username: string }) => {
  return (
    <StoryContainer>
      <StoryImage location={`${username}-${media.timestamp}`} />
      <div>
        <ul>
          <li>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
              <path d='M 16.6875 3 L 16.375 3.28125 L 9.59375 10 L 5 10 L 5 26 L 21.84375 26 C 23.253906 26 24.484375 25.003906 24.78125 23.625 L 26.9375 13.625 C 27.332031 11.777344 25.886719 10 24 10 L 18.25 10 L 18.4375 9.25 C 18.640625 9.09375 18.769531 9.027344 19.0625 8.625 C 19.53125 7.984375 20 6.992188 20 5.65625 C 20 4.230469 18.710938 3 17.09375 3 Z M 17.40625 5.09375 C 17.828125 5.175781 18 5.347656 18 5.65625 C 18 6.558594 17.726563 7.117188 17.46875 7.46875 C 17.210938 7.820313 17.03125 7.90625 17.03125 7.90625 L 16.6875 8.09375 L 16.5625 8.5 L 15.96875 10.75 L 15.65625 12 L 24 12 C 24.660156 12 25.105469 12.574219 24.96875 13.21875 L 22.84375 23.21875 C 22.742188 23.6875 22.320313 24 21.84375 24 L 11 24 L 11 11.40625 Z M 7 12 L 9 12 L 9 24 L 7 24 Z' />
            </svg>
            {media.likes}
          </li>
          <li>
            <span>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
                <path d='M 2 5 L 2 21 L 6 21 L 6 26.09375 L 7.625 24.78125 L 12.34375 21 L 22 21 L 22 5 Z M 4 7 L 20 7 L 20 19 L 11.65625 19 L 11.375 19.21875 L 8 21.90625 L 8 19 L 4 19 Z M 24 9 L 24 11 L 28 11 L 28 23 L 24 23 L 24 25.90625 L 20.34375 23 L 12.84375 23 L 10.34375 25 L 19.65625 25 L 26 30.09375 L 26 25 L 30 25 L 30 9 Z' />
              </svg>
            </span>
            {media.comments}
          </li>
        </ul>
        <p title={media.captions[0].node.text}>
          {media.captions[0].node.text.length > 50
            ? `${media.captions[0].node.text.substring(0, 50)}...`
            : media.captions[0].node.text}
        </p>
      </div>
    </StoryContainer>
  )
}

export default Story
