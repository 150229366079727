import React, { useEffect } from 'react'
import logo from '../icons/logo/logo-text-side.svg'
import styled from 'styled-components'
import Login from '../components/signup-flow/login'
import { StyledLogoText } from '../style/logo'

const LoginViewWrapper = styled.div`
  background-image: linear-gradient(to left, #1d3557, #264669, #30577a, #3a698c, #457b9d);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  .logo {
    margin-top: 4rem;
    svg {
      height: 200px;
    }
  }
`

const LoginView = () => {
  useEffect(() => {}, [])
  return (
    <LoginViewWrapper>
      <div className='logo'>
        <StyledLogoText color='#F1FAEE' />
      </div>
      <Login />
    </LoginViewWrapper>
  )
}

export default LoginView
