import { getDatabase, ref, update, get, query, orderByKey, startAt, endAt, remove, limitToFirst } from 'firebase/database'
import { useListVals } from 'react-firebase-hooks/database'
import { User } from '../types/user'
import { transformInstagramData } from '../utils/transformers'

export const writeUserData = async (uid: string, data: object) => {
  const db = getDatabase()
  update(ref(db, 'users/' + uid), {
    ...data,
  })
}

export const getUserData = async (uid: string) => {
  const db = getDatabase()
  const response = await get(ref(db, 'users/' + uid))
  if (response.exists()) {
    return response.val() as User
  } else {
    console.log('No data available')
    return null
  }
}

export const deleteUserDb = async (uid: string) => {
  const db = getDatabase()
  const response = await remove(ref(db, 'users/' + uid))
}

/**
 * Fetch the correct amount of data from the db
 */
export const useDatabase = (id: string = '1c5zDt-Kyy2rFFOXxSyv-1GWI38YWkaOB73VJiaUP8bY/influencers', limit?: number) => {
  const endNumber = 0
  const db = getDatabase()

  // for now we only use the ig transformer on the database data
  const transformer = id === '1c5zDt-Kyy2rFFOXxSyv-1GWI38YWkaOB73VJiaUP8bY/influencers' ? transformInstagramData : undefined
  const [value, loading, error] = useListVals(query(ref(db, id), limitToFirst(500)), { transform: transformer })

  return {
    data: value,
    loading,
    error,
  }
}
