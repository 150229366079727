import React, { FC, useEffect, useState } from 'react'
import { ExpanderComponentProps } from 'react-data-table-component'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useUserData } from '../../../hooks/user'
import { Influencer } from '../../../types/influencer'
import InfluencerCharts from './influencerCharts'
import ProfileCard from './profileCard'
import ProfileInfo from './profileInfo'
import Statistics from './statistics'
import InfluencerStoryContainer from './influencerStoryContainer'

const StyledRow = styled.pre`
  width: 100%;
  margin: auto;
  background-color: #f1faee;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: auto;
  grid-template-areas:
    'profile-image stats charts'
    'name-info name-info charts'
    'hashtags hashtags charts'
    'stories stories  stories';
  justify-content: center;
  align-items: center;
  /* padding: 2rem;*/
  padding-bottom: 1rem;
  .name-info {
    text-align: left;
    grid-area: name-info;
    display: flex;
    align-items: center;
    border-top: 2px solid #457b9d;
    margin-top: 1rem;
    border-bottom: 2px solid #457b9d;
    margin-bottom: 1rem;
    column-gap: 1rem;
    justify-content: space-between;
  }
  .profile-image {
    grid-area: profile-image;
    display: flex;
    justify-content: center;
    text-align: left;
    font-size: 0.7rem;
    font-family: sans-serif;
    font-weight: 800;
    div {
      margin: auto;
    }
    .private {
      color: #e63946;
    }
    .link {
      border-radius: 12px;
      padding: 0.2rem 0.3rem;
      a {
        text-decoration: none;
        color: inherit;
        display: flex;
        justify-content: center;
      }
    }
    svg {
      height: 1rem;
      width: 1rem;
      fill: #1d3557;
    }
    p {
      display: flex;
      justify-content: start;
      align-items: center;
    }
  }
  .stories {
    grid-area: stories;
    svg {
    }
  }
  .hashtags {
    grid-area: hashtags;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.7rem;
    height: 80%;
  }
  .charts {
    grid-area: charts;
  }
`

const UpgradeButton = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    background: rgba(29, 53, 87, 1);
    color: white;
    width: 12rem;
    height: 3rem;
    cursor: pointer;
    text-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const ExpandRow: FC<ExpanderComponentProps<any>> = ({ data }: { data: Influencer }) => {
  return (
    <StyledRow>
      <ProfileCard className='profile-image' data={data} />
      <ProfileInfo className='name-info' data={data} />
      <Statistics className='stats' data={data} />
      <InfluencerCharts className='charts' data={data?.medias} />
      <div className='hashtags'>{data.hashtags && data.hashtags.split(' ').map((item) => <span>{item} </span>)}</div>
      <div className='stories'>
        <InfluencerStoryContainer medias={data.medias} username={data.username} />
      </div>
    </StyledRow>
  )
}

export default ExpandRow
