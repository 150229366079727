import { InfluencerDB, Media } from '../types/influencer'

export const transformInstagramData = (data: any) => {
  const username = data.username
  // Follower stats
  const followers = data.followers_temp
  const following = data.following_temp
  const followRatio = followers / following

  // media

  // length to use for getting averages
  const mediaCount = data.medias?.length || 1
  // media entries
  const medias = data.medias
  let mediaComment = 0
  let mediaLikes = 0

  if (medias) {
    medias.map((media: Media) => {
      const comments = media.comments
      mediaComment = mediaComment + comments
      const likes = media.likes
      mediaLikes = mediaLikes + likes
    })
  }
  const averageLikes = mediaLikes / mediaCount
  const averageComments = mediaComment / mediaCount

  const averageEngagement = (averageLikes + averageComments) / followers
  // videos

  //console.log(JSON.stringify(data))
  // number of video entries
  const videoCount = data.backup?.user.edge_felix_video_timeline?.edges?.length
  // video entries
  const videos = data.backup?.user.edge_felix_video_timeline.edges
  // video ratio
  const videoRatio =
    data.backup?.user.edge_felix_video_timeline.count &&
    data.backup?.user.edge_felix_video_timeline.count / data.backup.user.edge_owner_to_timeline_media.count

  let videoComment = 0
  let videoLikes = 0
  let videoViewCount = 0
  if (videos) {
    videos.map((video: any) => {
      const comments = video.node.edge_media_to_comment.count
      videoComment = videoComment + comments
      const likes = video.node.edge_liked_by.count
      videoLikes = videoLikes + likes
      const views = video.node.video_view_count
      videoViewCount = videoViewCount + views
    })
  }

  const averageVideoComments = videoComment / videoCount
  const averageVideoLikes = videoLikes / videoCount
  const averageVideoViews = videoViewCount / videoCount

  // how many view the video
  const averageVideoReach = averageVideoViews / followers
  // how many of the viewers engage
  const averageVideoEngagement = (averageVideoLikes + averageVideoComments) / averageVideoViews

  // Data to visualize
  const influencerData: InfluencerDB = {
    username,
    followers,
    following,
    followRatio,
    averageLikes,
    averageComments,
    averageEngagement,
    averageVideoLikes,
    averageVideoComments,
    averageVideoViews,
    averageVideoReach,
    averageVideoEngagement,
    videoRatio,
    ...data,
  }
  return influencerData
}
