import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@firebase/auth'
import { getUserData, writeUserData } from '../firebase/utils'
import Database from '../modules/database/databaseContainer'
import styled from 'styled-components'
import { AccentBanner } from '../style/banners'
import UpgradeBanner from '../components/banners/updgrade-banner'
import { User } from '../types/user'
import FullscreenSpinner from '../components/loader/fullscreen-spinner'

const DatabaseViewWrapper = styled.div`
  max-width: calc(100vw - 4rem);
  flex: 1;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const DatabaseView = () => {
  const [user, userLoading, userError] = useAuthState(getAuth())
  const [access, setAccess] = useState<User['paymentMode'] | false>(false)
  const [dbId, setDbId] = useState<string>()

  const checkForAccess = async () => {
    // check if we have an user
    if (user) {
      // Get the user data
      const response = await getUserData(user?.uid)
      if (!response) {
      } else if (response.paymentMode) {
        // we have db access render full db
        setAccess(response.paymentMode)
      } else {
        // no db access and no lists
        setAccess(false)
      }
    }
  }

  useEffect(() => {
    if (user) {
      checkForAccess()
    }
  }, [userLoading])

  const getAccess = () => {
    if (user) {
      writeUserData(user.uid, { paymentMode: 'free' })
    }
  }

  if (userLoading) {
    return (
      <DatabaseViewWrapper>
        <FullscreenSpinner />
      </DatabaseViewWrapper>
    )
  }

  if (!userLoading && !access) {
    return (
      <DatabaseViewWrapper>
        <FullscreenSpinner />
      </DatabaseViewWrapper>
    )
  }

  return (
    <DatabaseViewWrapper>
      {/**
       * Add banner here if user only have sample access
       */}
      {access === 'free' && <UpgradeBanner headline='Want more influencers?' text={'Upgrade to unlock all'} />}
      <Database />
    </DatabaseViewWrapper>
  )
}

export default DatabaseView
