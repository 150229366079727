import React, { Dispatch, SetStateAction, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { StyledLogoText } from '../../style/logo'
import { promotionalCodes } from '../../code.js'
import { BasicButton } from '../../style/buttons'

const LoginWrapper = styled.div`
  background-color: white;
  width: 33%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    margin-top: 4rem;
    svg {
      height: 200px;
    }
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`
const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  h1 {
    margin: 0;
  }
  div {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0.8rem 0;
  }
  input {
    flex: 1;
    width: 100%;
    height: 2rem;
    padding: 0.6rem;
    color: #1d3557;
    background: #fff;
    border-radius: 6px;
    border: 2px solid #a8dadc;
    box-sizing: border-box;
    filter: none !important;
    transition: all 0.3s ease;
    &:focus {
      border: 2px solid #457b9d;
      outline: none;
    }
  }
`
const GoogleSignInWrapper = styled.div`
  margin-top: 2rem;
  padding: 0.2rem 0;
  width: 67%;
`
const CustomLink = styled(Link)`
  color: #3b3a3a;
  font-weight: bolder;
`

const ErrorWrapper = styled.p`
  color: #e63946;
`

const CodeRedeemer = ({ isValidCode }: { isValidCode: Dispatch<SetStateAction<boolean>> }) => {
  const promoCodes = promotionalCodes
  const [code, setCode] = useState<string | undefined>()
  return (
    <LoginWrapper>
      <div className='logo'>
        <StyledLogoText color='#1D3557' />
      </div>
      <LoginForm
        onSubmit={(e) => {
          e.preventDefault()
          if (code && promoCodes.includes(code)) {
            isValidCode(true)
          }
        }}
      >
        <div>
          <label>Appsumo Code</label>
          <input
            type='text'
            placeholder='Paste your appsumo code here'
            onChange={(e) => setCode(e.target.value)}
            value={code}
          />
        </div>
        <BasicButton>Validate</BasicButton>
      </LoginForm>
    </LoginWrapper>
  )
}

export default CodeRedeemer
