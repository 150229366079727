import React from 'react'
import styled from 'styled-components'
import { BasicButton } from '../../style/buttons'

const Wrapper = styled.div`
  height: 90%;
  width: 75%;
  border-radius: 8px;
  align-self: center;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  flex-direction: column;
  text-align: left;
  h1 {
    text-align: center;
    margin-bottom: 4rem;
  }
  h2,
  p {
    margin: 0.2rem;
  }
  padding: 0 8rem;
  .cell2 {
    border-top: 1px solid #1d3557;
    padding-top: 2rem;
  }
  a {
    color: #457b9d;
  }
  .buttonlink {
    color: inherit !important;
    text-decoration: none !important;
  }
`

const Headline = styled.div`
  border-bottom: 2px solid #a8dadc;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  p {
    margin-bottom: 2rem;
  }
`
const Footer = styled.div`
  text-align: center;
  margin-top: 2rem;
  code {
    border-bottom: 1px solid black;
  }
`

const Upgrade = () => {
  return (
    <Wrapper>
      <h1>Subscribe for full access</h1>
      <Headline>
        <p>
          Omnifluence is a relatively new project with a high ambition! By subscribing you get more features and additional influencer
          data and you give us the opportunity to keep evolving, improving and adding new features..
        </p>
        <a href='https://buy.stripe.com/14kaGegr46kydc4000' target={`_blank`}>
          <BasicButton primary>Subscribe</BasicButton>
        </a>
      </Headline>
      <div>
        <h2>Feature Roadmap</h2>
        <ul>
          <h3>Done</h3>
          <li>Full influencer database</li>
          <li>All influencer statistics</li>
          <li>Charts for likes & comments</li>
          <li>Custom List orders</li>
          <li>3 Linkbee links</li>
          <h3>Comin soon</h3>
          <li>More influencers (+600 per month)</li>
          <li>Exports to excel or google docs</li>
          <li>Add you own influencers</li>
          <li>Bookmarks, favorites & notes</li>
          <li>Growth statistics</li>
          <li>Campaign manager</li>
        </ul>
      </div>
      <Footer>
        <p>
          Contact us at <code>hey@omnifluence.io</code> for more information and how to join.
        </p>
      </Footer>
    </Wrapper>
  )
}

export default Upgrade
