import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset,
  ChartOptions,
  ChartData,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export const LineChart = ({ dataset, options }: { dataset: ChartData<'line'>; options: any }) => {
  // no dataset no chart
  if (!dataset) {
    return null
  }

  return <Line options={options} data={dataset} />
}
