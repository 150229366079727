import { getAuth } from '@firebase/auth'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const ModalWrapper = styled.ul`
  text-decoration: none;
  margin: 0;
  list-style: none;
  position: absolute;
  right: 0;
  background-color: #f1faee;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  padding: 0.2rem 0.6rem;
  font-size: 0.8rem;
  color: black;
  & > * + * {
    border-top: 2px solid #1d3557;
  }
  li {
    margin: 0.2rem 0;
    &:hover {
      background-color: #a8dadc;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
  }
`

const UserModal = ({ visible }: { visible?: boolean }) => {
  const signOut = async () => {
    getAuth().signOut()
  }
  return (
    <ModalWrapper>
      <li>
        <Link to='/upgrade'>Upgrade</Link>
      </li>
      <li>
        <Link to='/settings'>Settings</Link>
      </li>
      <li onClick={signOut}>Logout</li>
    </ModalWrapper>
  )
}

export default UserModal
