import React from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { useDatabase } from '../../firebase/utils'
import { influencerColumns } from '../../modules/database/dataColumns'
import FullscreenSpinner from '../loader/fullscreen-spinner'
import Table from '../table/table'

const TableWrapper = styled.div`
  margin: 0 2rem;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3),
    0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  width: 84vw;
`

const ListTable = () => {
  const { state } = useLocation()
  const listId = state?.listId
  const listName = state?.listName
  const { data, loading, error } = useDatabase(listId)
  const title = listName || ''

  if (loading) {
    return <FullscreenSpinner />
  }
  if (error) {
    console.error(error)
    return <h1>Something went wrong finding the data</h1>
  }
  const columns = influencerColumns

  return (
    <TableWrapper>
      <Table columns={columns} data={data} progressPending={loading} title={title} />
    </TableWrapper>
  )
}

export default ListTable
