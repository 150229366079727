import React from 'react'
import styled from 'styled-components'
import Upgrade from '../components/upgrade/upgrade'

const Wrapper = styled.div`
  flex: 1;
  background-color: #f1faee;
  display: flex;
  justify-content: center;
`

/**
 *
 * @returns view depending on if the user has premium or not
 */
const UpgradeView = () => {
  // if hasPremium
  if (false) {
    return null
  }

  return (
    <Wrapper>
      <Upgrade />
    </Wrapper>
  )
}

export default UpgradeView
